<template>
  <VExpansionPanels>
    <VExpansionPanel>
      <VExpansionPanelHeader class="header pl-4">
        {{ title }}
      </VExpansionPanelHeader>
      <VExpansionPanelContent>
        <template v-if="keys.length > 6">
          <Fragment
            v-for="(element, _, index) in elements"
            :key="index"
          >
            <VRow
              v-for="(item, key, indexChildren) in element"
              :key="indexChildren"
              style="margin-top:0px"
            >
              <VCol
                cols="12"
                sm="5"
                v-text="startCase(key)"
              />
              <VCol
                cols="12"
                sm="7"
                v-text="item"
              />
            </VRow>
          </Fragment>
        </template>
        <Fragment v-else>
          <VRow class="pt-4">
            <VCol
              v-for="key in keys"
              :key="key"
              :cols="parseInt(12 / Object.keys(firstElement).length)"
              v-text="startCase(key)"
            />
          </VRow>
          <VRow
            v-for="(item, _, index) in elements"
            :key="index"
          >
            <VCol
              v-for="(__, keyChildren, indexChildren) in firstElement"
              :key="indexChildren"
              :cols="parseInt(12 / Object.keys(firstElement).length)"
              class="pa-2"
              v-text="item[keyChildren]"
            />
          </VRow>
        </Fragment>
      </VExpansionPanelContent>
    </VExpansionPanel>
  </VExpansionPanels>
</template>

<script>
import { startCase } from 'lodash'
import { Fragment } from 'vue-frag'

export default {
  name: 'CMExpansionDetails',

  components: {
    Fragment
  },

  props: {
    title: {
      type: String,
      default: null
    },
    elements: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    startCase () {
      return startCase
    },

    firstElement () {
      return this.elements.at(0)
    },

    keys () {
      return Object.keys(this.firstElement)
    }
  }
}
</script>

<style scoped>
.header {
  font-size: 0.875rem;
  border-bottom: 1px solid #393939;
}
</style>
